@import "../../style/colors";

.button {
  background: none;
  border: none;
  color: $blue;
  cursor: pointer;
  font: inherit;
  outline: none;
}
