@import "../style/colors.scss";

.Home {
  position: relative;

  .competition {
    background-color: $lightBlue;
    background-image: url('../images/my-competition.jpg');
    background-size: cover;
    border-radius: 5px;
    color: white;
    margin-top: 2em;
    padding: 1em 2em;

    a {
      color: white;
    }
  }

  .signup {
    background-color: $lightBlue;
    border-radius: 5px;
    color: white;
    margin-top: 1em;
    padding: 1em 2em;
    @media only screen and (min-width: 1000px) {
      max-width: 50%;
    }
  }

  strong {
    display: inline-block;
    min-width: 200px;

    @media screen and (max-width: 650px) {
      display: block;
    }
  }

  @media screen and (min-width: 650px) {
    .button {
      width: calc(50% - 5px);
      margin-right: 10px;
    }

    .lastButton {
      width: calc(50% - 5px);
    }
  }

  .error {
    border-radius: 5px;
    border: 1px red solid;
    background-color: red;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .warning {
    border-radius: 5px;
    border: 1px #ff6800 solid;
    background-color: #ff6800;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}