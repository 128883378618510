.Overview {
    .container {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        column-gap: 0.5em;
        row-gap: 0.5em;

        div:not(.registrations) {
            background-color: #f2f2f2;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 1em;
            position: relative;

            &.details {
                grid-column-start: 1;
                grid-column-end: 4;
            }
    
            i {
                position: absolute;
                right: 0.5em;
                top: 0.6em;
            }

            ul {
                padding-inline-start: 1em;
            }
        }

        .registrations {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 0.1em;
            row-gap: 0.1em;
        }

        p {
            margin: 0;
        }
    }
}