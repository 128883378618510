.CreateCourtSchedule {

  input[type='text'], input[type='checkbox'] {
    margin: 5px 10px;
    width: auto;
    display: inline-block;
  }

  input[type='text'].court {
    width: 50px;
  }

  .date {
    display: inline-block;
    width: 120px;
  }

  .dayTimes, input[type='time'] {
    width: 120px;
    margin-right: 10px;
    display: inline-block;
  }
  
  .days {
    display: flex;

    label {
      display: flex;
      align-items: center;
    }
  }
}
