@import "../style/colors";

.Breadcrumb {
  padding: 0 0 10px 0;
  list-style: none;

  li {
    color: $blue;
    display: inline;
    font-size: 11px;
    padding-right: 8px;
  }

  li:before {
    padding-right: 8px;
    color: $blue;
    content: "\003e";
  }
}
