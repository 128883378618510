
.form {
  display: flex;
  flex-wrap: wrap;
  
  > div {
    flex-grow: 1;
    margin-right: 1em;
  }
}

.club {
  display: flex;
  
  button {
    margin-left: 1em;
  }
}

input.add {
  width: 15em;
  height: 2em;
  margin-top: 0.3em;
  background-color: #f2f2f2;
  color: #555
}