.registrations {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: minmax(auto, 300px) 10px minmax(auto, 300px);
  grid-template-rows: auto auto auto;
  align-items: center;

  .player {
    position: relative;
    top: -7px;
    left: 5px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 28px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
}