@import "./style/colors";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {box-sizing: border-box}

i.fa, i.far, i.fas {
  cursor: pointer;
  font-size: 22px;
  margin-right: 10px;

  &.disabled {
    color: $disabled;
    cursor: default;
  }
}

i.fa-info-circle {
  color: $blue;
}

i.tooltip {
  position: relative;
  display: inline-block;

  div {
    background-color: #eee;
    border-radius: 6px;
    color: #555;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 16px;
    font-weight: normal;
    min-width: 10em;
    max-width: 15em;
    opacity: 0;
    padding: 5px;
    position: absolute;
    text-align: left;
    transition: opacity 0.3s;
    visibility: hidden;
    z-index: 1;

    top: 0;
    bottom: auto;
    right: 128%;
  }

  div::after {
    content: "";
    position: absolute;
    top: 12px;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
  }
}
.tooltip:hover div {
  visibility: visible;
  opacity: 1;
}

td.icons {
  text-align: right;
}

ul.typeahead-selector {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:first-child {
    border: #ccc 1px solid;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  li {
    border-left: #ccc 1px solid;
    border-right: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
    cursor: pointer;
    padding: 5px;

    button:hover {
      text-decoration: none !important;
    }
  }
  li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
  max-width: 400px;

  &.nomax {
    max-width: none;
  }

  label, input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  label {
    color: $blue;
  }

  input, select {
    font-size: inherit;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    // -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
  }

  input:focus {
    outline: 0;
    border-bottom: 1px solid #666;
  }
  
  input:placeholder-shown + label {
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 1.5rem) scale(1.2);
  }
  
  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
  }
}

/* style inputs and link buttons */
input,
select,
textarea,
.btn {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none; /* remove underline from anchors */
  outline: none;

  &:hover {
    opacity: 1;
  }
}

input[type="button"], input[type="submit"] {
  background-color: $green;
  border: 0;
  color: white;
  cursor: pointer;
  max-width: 400px;
  margin-right: 0.5em;
  display: inline-block;

  &:disabled {
    background-color: #dddddd;
    color: gray;
    cursor: auto;
  }

  &.bright {
    background-color: $orange;
  }

  &.second {
    background-color: gray;
  }
}

input[type="checkbox"] {
  height: 30px;
}

.btn {
  border: none;
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #2196F3;
    }

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
}

div.table.overfow {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;

  .selectable {
    cursor: pointer;
  }

  .selected {
    background-color: #7FFFD4;
  }
}

th, td {
  text-align: left;
  padding: 6px;
}

tr {
  border-bottom: 1px solid #ddd;

  &:hover td {
    background-color: #c3d6e8;
  }

  &.dateHeader td {
    background-color: #f2f2f2;
  }

}

hr {
  margin-inline-start: 0;
  max-width: 400px;
  border: 0;
  border-top: 4px solid $blue;
  border-radius: 2px;
}

hr.adsense {
  max-width: none;
  border: 0;
  border-top: 1px solid #eeeeee;
}

@media screen and (max-width: 650px) {
  th.hide1, td.hide1 {
    display: none;
  }

  body {
    font-size: 12px !important;
  }

  .sidenav a, .sidenav .dropBtn, .sidenav .button, input {
    font-size: 14px !important;
  }
}

.success {
  border-radius: 5px;
  border: 1px #06a700 solid;
  background-color: #06a700;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.info {
  border-radius: 5px;
  border: 1px #00b5e8 solid;
  background-color: #00b5e8;
  color: white;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.popup {
  background-color: rgba(0,0,0,0.4);
  height: 100%;
  left: 0;
  overflow: auto;
  padding-top: 100px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .header {
    background-color: $blue;
    border-radius: 5px 5px 0 0;
    color: white;
    font-weight: bold;
    margin: auto;
    padding: 10px 20px;
    width: 80%;
  }

  .content {
    background-color: #fefefe;
    border-radius: 0 0 5px 5px;
    margin: auto;
    padding: 20px;
    width: 80%;
  }

  @media screen and (min-width: 650px) {
    .button {
      margin-right: 10px;
      width: 200px;
    }

    .lastButton {
      width: 200px;
    }
  }
}

.filter {
  display: grid;
  grid-template-columns: minmax(0, 500px) 50%;
  grid-column-gap: 10px;
  width: calc(100% - 10px);
}

.errorInput {
  border-color: red;
}

.errorText {
  color: red;
}

.warningText {
  color: orange;
}

/* Remove up and down arrows for number fields in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove up and down arrows for number fields in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

p.error {
  color: red;
  margin: 0;
  max-width: 400px;

  &::before {
    display: inline;
    content: "⚠ ";
  }
}