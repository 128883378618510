.twoColumns {
    display: grid;
    grid-column-gap: 5px;
    grid-template-columns: 40% 60%;

    .container {
        height: 60vh;
        overflow: auto;

        > div {
            overflow-x: unset;
        }
    }

    .groups {
        display: grid;
        grid-column-gap: 5px;
        grid-template-columns: 60px auto;

        .groupHeader {
            background-color: #777;
            border: #ccc 1px solid;
            border-radius: 6px;
            color: white;
            margin-bottom: 0.2em;
            padding: 0.5em;
        }
    }
}