@import "../../style/colors";

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: $lightBlue;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  a, .dropBtn, .button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 20px;
    outline: none;
    padding: 8px 8px 8px 32px;
    text-align: left;
    text-decoration: none;
    transition: 0.3s;
    width: 100%;
    white-space: nowrap;

    i {
      margin-left: 5px;
    }

    &:hover {
      background-color: #555;
    }
  }

  .dropdown-content {
    display: none;
    background-color: $blue;
    padding-left: 8px;
  }

  .closeBtn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 50px;
    font-size: 36px;
    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      background-color: #555;
    }
  }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.topnav {
  /* Add a black background color to the top navigation */
  background-color: $blue;
  overflow: hidden;
  z-index: 10;

  /* Style the links inside the navigation bar */
  a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

  hr {
    border: 0;
    border-top: 1px solid $blue;
  }

  /* Add an active class to highlight the current page */
  .active {
    background-color: $orange;
    color: white;
  }

  .button {
    background-color: $blue;
    border: none;
    color: #f2f2f2;
    cursor: pointer;
    display: block;
    font-size: 17px;
    outline: none;
    padding: 18px 16px 12px;

    &:hover {
      background-color: #555;
    }
  }

  /* Hide the link that should open and close the topnav on small screens */
  .icon {
    background-color: $blue;
    border: none;
    color: white;
    cursor: pointer;
    display: none;
    font-size: 17px;
    outline: none;
    padding: 14px 16px;

    &:hover {
      background-color: #555;
    }
  }

  /* Dropdown container - needed to position the dropdown content */
  .dropdown {
    float: left;
    overflow: hidden;

    &.auth {
      float: right;

      .dropdown-content {
        right: 0;
      }
    }

    /* Style the dropdown button to fit inside the topnav */
    .dropBtn {
      font-size: 17px;
      border: none;
      outline: none;
      color: white;
      padding: 14px 16px;
      background-color: $blue;
      font-family: inherit;
      margin: 0;

      i {
        margin-left: 5px;
      }    }

    /* Style the dropdown content (hidden by default) */
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 2;

      /* Style the links inside the dropdown */
      a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }

      /* Add a grey background to dropdown links on hover */
      a:hover {
        background-color: #ddd;
        color: black;
      }
    }
  }

  /* Add a dark background on topnav links and the dropdown button on hover */
  a:hover, .dropdown:hover .dropbtn {
    background-color: #555;
    color: white;
  }

  /* Show the dropdown menu when the user moves the mouse over the dropdown button */
  .dropdown:hover .dropdown-content {
    display: block;
  }
}

/* When the screen is less than 600 pixels wide, hide all links. Show the link that contains should open and close the sidenav (.icon) */
@media screen and (max-width: 900px) {
  .topnav a, .topnav .dropdown .dropBtn, .topnav .button {
    display: none;
  }
  .topnav .icon {
    float: right;
    display: block;
  }
}
