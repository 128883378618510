@import "../../style/colors";

.container {
    position: relative;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px 0 30px 30px;
    margin-top: 20px;

    /* vertical line */
    .vl {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        border: 2px solid #ddd;
        height: 175px;
    }

    /* text inside the vertical line */
    .inner {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 8px 10px;
    }

    /* hide some text on medium and large screens */
    .hideMdLg {
        display: none;
    }
}

.form {
    margin: 6px auto auto auto;
}

.info {
    border-radius: 5px;
    border: 1px #00b5e8 solid;
    border-left: 10px #00b5e8 solid;
    background-color: #ececec;
    margin-bottom: 10px;
    padding: 10px;
}

/* Two-column layout */
.col {
    float: left;
    width: 50%;
    margin: 6px auto auto auto;
    padding: 0 50px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
/* bottom container */
.bottomContainer {
    text-align: center;
    background-color: #666;
    border-radius: 0 0 4px 4px;
}

/* Responsive layout - when the screen is less than 650px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 650px) {
    .col {
        width: 100%;
        margin-top: 0;
    }
    /* hide the vertical line */
    .vl {
        display: none;
    }
    /* show the hidden text on small screens */
    .hideMdLg {
        display: block;
        text-align: center;
    }
}

input[type="radio"] {
    float: left;
    clear: none;
    margin-right: 10px;
    width: auto;
}

// Disabled becasue of edit competition issue select behind label
// label {
    // float: left;
// }