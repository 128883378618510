@media only screen and (max-width: 1000px) {
  .div1 {
    // min-height: 60em;

    img {
      width: 200px;
    }
  }
  .div2 {
    img {
      width: 400px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .div1 {
    min-height: 26em;

    img {
      width: 600px;
    }
  }
  .div2 {
    min-height: 22em;

    img {
      width: 600px;
    }
  }
}
