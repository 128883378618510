.main {
  transition: margin-left .5s;
  padding: 0 16px 16px;
}

.lds-dual-ring {
  height: 64px;
  margin: 100px auto 0 auto;
  width: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

