.weekPlanning {

  table {
    border: none;
    width: auto;
  }

  tr {
    border: none;
    height: 50px;

    &:hover td {
      background-color: inherit;
    }
  }

  .check {
    position: relative;
    padding: 4px 8px 4px 40px;
    border-radius:40px;
    margin-bottom:4px;
    min-height:30px;
    align-items: center;
  }

  .check:last-child {
    margin-bottom:0;
  }

  .check span {
    position: relative;
    vertical-align: middle;
    line-height: normal;
  }

  input[type="checkbox"] {
    display: inline-block;
    appearance: none;
    position: absolute;
    top:50%;
    left:5px;
    transform:translateY(-50%);
    background-color: #F44336;
    width:20px;
    height:20px;
    margin:0;
    outline: none;
    transition:background-color .5s;
  }

  input[type="checkbox"]:before {
    content:'';
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#ffffff;
    width:20px;
    height:5px;
    transition:all .5s;
  }

  input[type="checkbox"]:after {
    content:'';
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%) rotate(-45deg);
    background-color:#ffffff;
    width:20px;
    height:5px;
    transition:all .5s;
  }

  input[type="checkbox"]:checked {
    background-color:#4CAF50;
  }

  input[type="checkbox"]:checked:before {
    content:'';
    position: absolute;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%) translate(-4px,3px) rotate(45deg);
    background-color:#ffffff;
    width:12px;
    height:5px;
  }

  input[type="checkbox"]:checked:after {
    content:'';
    position: absolute;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%) translate(3px,2px) rotate(-45deg);
    background-color:#ffffff;
    width:16px;
    height:5px;
  }
}