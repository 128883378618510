.CreateMatchSchedule {

  select, input[type='checkbox'] {
    margin: 0;
  }

  select {
    padding: 5px 12px
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}
